import React, { useEffect, useState } from "react";
import Dropzone from "./Dropzone";
import Loading from "./Loading";
import cx from "classnames";
import styles from "./styles.module.css";
import logo from "./logo.svg";

const FAKE_DATA = {
  breed: {
    "8": "Rhodesian_ridgeback",
    "28": "Staffordshire_bullterrier",
    "29": "American_Staffordshire_terrier",
    "56": "golden_retriever",
    "57": "Labrador_retriever",
  },
  prob: {
    "8": 0.013585826382040977,
    "28": 0.006474744528532028,
    "29": 0.0044029708951711655,
    "56": 0.2118275761604309,
    "57": 0.7362479567527771,
  },
};

interface PredictResponse {
  breed: Record<string, string>;
  prob: Record<string, number>;
}

const makePrediction = async (base64: string): Promise<PredictResponse> => {
  const res = await fetch(`/api/predict`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ base64 }),
  });
  return res.json();
};

const toPer = (num: number): string => {
  return `${(num * 100).toFixed(2)}%`;
};

const convertBase64 = (file: File): Promise<string | ArrayBuffer | null> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.onerror = (error) => {
      reject(error);
    };
  });
};

function App() {
  const [
    predictionResult,
    setPredictionResult,
  ] = useState<PredictResponse | null>(null);
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState<string | null>(null);
  return (
    <div className={styles.container}>
      <h1>Jiayi's dog predictor</h1>
      <div className={styles.app}>
        <Dropzone
          onDrop={async (file: File) => {
            const base64 = await convertBase64(file);
            if (base64 && typeof base64 === "string") {
              setLoading(true);
              setImage(base64);
              const prediction = await makePrediction(base64);
              setPredictionResult(prediction);
              setLoading(false);
            }
          }}
        />

        <div
          className={cx(styles.result, {
            [styles.visible]: loading || predictionResult,
          })}
        >
          {image && <img src={image} className={styles.uploadImage} />}
          {loading ? (
            <Loading />
          ) : (
            predictionResult && (
              <div className={styles.predictionResult}>
                {Object.keys(predictionResult.breed)
                  .sort((a, b) => {
                    return predictionResult.prob[a] > predictionResult.prob[b]
                      ? -1
                      : 1;
                  })
                  .filter((key) => {
                    return +predictionResult.prob[key] * 100 > 1;
                  })
                  .map((key) => (
                    <div key={key} className={styles.predictionResultItem}>
                      <div className={styles.predictionResultName}>
                        {predictionResult.breed[key].replace(/_/g, " ")}
                        :&nbsp;
                      </div>
                      <div>{toPer(predictionResult.prob[key])}</div>
                    </div>
                  ))}
              </div>
            )
          )}
        </div>
      </div>
    </div>
  );
}

export default App;

/*
        <input
          type="file"
          multiple={false}
          onChange={async (event) => {
            const file = event.target?.files?.[0];
            if (file) {
              const base64 = await convertBase64(file);
              if (base64 && typeof base64 === "string") {
                setLoading(true);
                setImage(base64);
                const prediction = await makePrediction(base64);
                setPredictionResult(prediction);
                setLoading(false);
              }
            }
          }}
        />
        {image && <img src={image} className={styles.uploadImage} />}{" "}
        {loading ? (
          <div className={styles.loading}>Loading</div>
        ) : !predictionResult ? (
          <div className={styles.upload}>Upload an image to begin!</div>
        ) : (
          <div className={styles.predictionResult}>
            {Object.keys(predictionResult.breed)
              .sort((a, b) => {
                return predictionResult.prob[a] > predictionResult.prob[b]
                  ? -1
                  : 1;
              })
              .map((key) => (
                <div key={key} className={styles.predictionResultItem}>
                  <div className={styles.predictionResultName}>
                    {predictionResult.breed[key].replace(/_/g, " ")}:&nbsp;
                  </div>
                  <div>{toPer(predictionResult.prob[key])}</div>
                </div>
              ))}
          </div>
        )}
 */
