import React, { useState, useEffect, useRef } from "react";
import styles from "./dropzone.module.css";
import cx from "classnames";

interface Props {
  onDrop: (file: File) => void;
}
export default function Dropzone({ onDrop }: Props) {
  const [onDragOver, setOnDragOver] = useState(false);
  useEffect(() => {
    const fn = (e: any) => e.preventDefault();
    window.addEventListener("dragover", fn, false);
    return () => {
      window.removeEventListener("dragover", fn);
    };
  }, []);
  const ref = useRef<HTMLInputElement>(null);

  return (
    <div
      className={cx(styles.dropzone, {
        [styles.dragOver]: onDragOver,
      })}
      onDragOver={() => setOnDragOver(true)}
      onDragLeave={() => setOnDragOver(false)}
      onDrop={(e) => {
        e.preventDefault();
        const file = e.dataTransfer.files?.[0];
        setOnDragOver(false);
        file && onDrop(file);
      }}
    >
      <div className={styles.dropImageText}>
        Drop a dog image here, or{" "}
        <a href="#" onClick={() => ref?.current?.click()}>
          browse
        </a>
      </div>
      <input
        type="file"
        className={styles.hidden}
        onChange={(e) => {
          const file = ref?.current?.files?.[0];
          file && onDrop(file);
        }}
        ref={ref}
      />
    </div>
  );
}
